@import '../../sass/variables';

.title {
  display: block;
  margin: 0;
  font-size: 1.75rem;
  color: $colorWhite;
  text-align: center;
}

.content {
  position: relative;
  z-index: 1;
}

.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 30px;
  justify-content: center;
}

.button {
  flex-grow: 1;
}

@include media-breakpoint-up(md) {
  .title {
    font-size: 2.5rem;
  }

  .button {
    flex-grow: 0;
    width: auto;
  }
}
