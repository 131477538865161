@import '../../sass/variables';

.wrapper {
  position: relative;
  z-index: 1;

  :global {
    .slick-list {
      overflow: visible;
    }

    .slick-track {
      display: flex;
      align-items: center;
      overflow: visible;
    }

    .slick-initialized .slick-slide {
      display: flex;
      justify-content: center;
    }

    .slick-slide > div {
      position: relative;
      width: 100%;
      max-width: 1080px;
      overflow: hidden;
      opacity: 0;
      transition: transform 200ms linear, opacity 100ms linear, border 200ms linear,
        box-shadow 200ms linear, blur 200ms linear;
    }

    .slick-active > div {
      background: $colorWhite;
      border-radius: 6px;
      opacity: 1;
    }
  }
}

.sliderWrapper {
  position: relative;
}

.list {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 50px 0 40px;
  list-style: none;
}

.item {
  position: relative;
}

.label {
  position: absolute;
  bottom: 100%;
  left: 50%;
  display: block;
  padding: 10px 20px;
  margin-bottom: 15px;
  font-weight: $weight-bold;
  color: $colorWhite;
  white-space: nowrap;
  visibility: hidden;
  background-color: $colorBlack;
  border-radius: 4px;
  box-shadow: 0 20px 35px rgba($colorGray900, 0.05);
  opacity: 0;
  transform: translateX(-50%);

  &::after {
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 12px;
    height: 12px;
    content: '';
    background-color: $colorBlack;
    border-radius: 2px;
    transform: translateX(-50%) rotate(-45deg);
  }
}

.dotWrapper {
  display: block;
  padding: 10px;
  margin: -10px 0;
  background: transparent;
  border: none;
  appearance: none;

  &:hover .dot {
    background-color: rgba($colorBlue300, 0.5);
  }
}

.dot {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  background: $colorGray300;
  border-radius: 1px;
  transition: background 200ms $transition-function, transform 200ms $transition-function;
}

.arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  padding: 5px;
  color: $colorGray900;
  background-color: $colorWhite;
  border: none;
  border-radius: 3px;
  box-shadow: $box-shadow-light;
  transition: opacity $transition, transform $transition;
  transform: translate(0, -50%);

  &:disabled {
    pointer-events: none;
    opacity: 0;
    transform: translate(-5px, -50%);
  }

  &.prev:disabled {
    transform: translate(5px, -50%);
  }
}

.next {
  right: -10px;
}

.prev {
  left: -10px;
}

.icon {
  display: block;
  width: 20px;
  height: 20px;
}

.withShadow {
  :global {
    .slick-slider {
      box-shadow: 0 20px 35px rgba($colorGray900, 0.05);
    }
  }
}

.secondary {
  :global {
    .slick-slide > div {
      max-width: 776px;
      padding: 30px 20px;
      filter: blur(4px);
      border: 1px solid rgba($colorGray200, 0);
      box-shadow: 15px 15px 50px rgba($colorGray900, 0);
      opacity: 0.5;
    }

    .slick-active > div {
      filter: none;
      border: 1px solid $colorGray200;
      box-shadow: 15px 15px 50px rgba($colorGray900, 0.06);
      opacity: 1;
    }
  }
}

.isActive {
  .label {
    visibility: visible;
    opacity: 1;
  }

  .dot,
  & .dotWrapper:hover .dot {
    background: $colorBlue300;
  }
}

@include media-breakpoint-up(sm) {
  .arrow {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .next {
    right: -35px;
  }

  .prev {
    left: -35px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }
}

@include media-breakpoint-up(md) {
  .icon {
    width: 60px;
    height: 60px;
  }

  .next {
    right: 0;
  }

  .prev {
    left: 0;
  }

  .withArrows {
    padding-right: 60px;
    padding-left: 60px;
  }

  .secondary {
    :global {
      .slick-slide > div {
        padding: 30px 40px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .withArrows {
    padding-right: 80px;
    padding-left: 80px;
  }

  .secondary {
    :global {
      .slick-slide > div {
        padding: 60px 90px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .withArrows {
    padding-right: 40px;
    padding-left: 40px;
  }

  .sliderWrapper {
    max-height: 662px;
  }

  .prev {
    left: -30px;
  }

  .next {
    right: -30px;
  }
}
