@import '../../sass/variables';

.list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 30px;
  margin-bottom: 0;

  &.withSeparator {
    gap: 30px;
  }
}

ol.list {
  counter-reset: ordered-list;

  & .list {
    width: calc(100% + 30px);
    transform: translateX(-30px);
  }
}

.item {
  position: relative;
  display: flex;
  align-items: baseline;

  &.withSeparator {
    &.separator-light {
      &::after {
        opacity: 0.1;
      }
    }

    &::after {
      position: absolute;
      bottom: -15px;
      left: -30px;
      z-index: 1;
      display: block;
      width: calc(100% + 30px);
      height: 1px;
      content: '';
      background: $colorGray300;
    }
  }
}

.bulletWrapper {
  position: absolute;
  left: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1lh; // line-height unit
}

.counter {
  display: flex;
  align-items: baseline;
  font-size: 0.625rem;
  font-weight: $weight-bold;

  &::before {
    content: counters(ordered-list, '.') '.';
    counter-increment: ordered-list;
  }
}

@include media-breakpoint-up(sm) {
  .list {
    gap: var(--gap);
    padding-left: var(--padding);
  }

  ol.list {
    & .list {
      width: calc(100% + var(--padding));
      transform: translateX(calc(var(--padding) * -1));
    }
  }

  .item {
    &.withSeparator::after {
      bottom: calc(var(--line-offset) * -1);
      left: calc(var(--padding) * -1);
      width: calc(100% + var(--padding));
    }
  }

  .counter {
    font-size: inherit;
  }

  .bulletWrapper {
    left: calc(var(--padding) * -1);
  }
}
