@import '../../../sass/variables';

.section {
  position: relative;
  overflow: hidden;
  background: $colorBlue800;
}

.background {
  position: absolute;
  top: 0;
  right: 90px;
  width: 569px;
  height: 569px;
  opacity: 0.55;
  transform: translateY(-50%);
}

.linesBackground {
  position: absolute;
  top: -270px;
  right: 0;
}

.content {
  position: relative;
  z-index: 1;
}
