@import '../../../../sass/variables';

.wrapper {
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 90px;
}

.sticky {
  display: none;
}

@include media-breakpoint-up(lg) {
  .wrapper {
    display: grid;
    grid-template-columns: auto 400px;
    gap: 60px;
  }

  .content {
    display: flex;
    gap: 280px;
  }

  .sticky {
    position: sticky;
    top: $nav-height;
    display: flex;
    align-items: center;
    width: 100%;
    height: 600px;
  }
}

@media (min-height: 800px) {
  .sticky {
    top: calc($nav-height + 10vh);
  }
}

@media (min-height: 1000px) {
  .sticky {
    top: calc($nav-height + 20vh);
  }
}

@include media-breakpoint-up(xl) {
  .wrapper {
    grid-template-columns: auto 475px;
  }
}
