@import '../../sass/variables';

.icon {
  display: inline-block;

  svg {
    display: block;
    fill: currentColor;
  }
}

// Opacity
.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-100 {
  opacity: 1;
}

// Colors
.color-currentColor {
  color: currentColor;
}

.color-blue200 {
  color: $colorBlue200;
}

.color-green400 {
  color: $colorGreen400;
}

.color-yellow200 {
  color: $colorYellow200;
}

.color-gray800 {
  color: $colorGray800;
}
