@import '../../../../../sass/variables';

.wrapper {
  position: relative;
  margin: 0 -20%;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    pointer-events: none;
    content: '';
    background: linear-gradient(
      to bottom,
      $colorGray100 0%,
      rgba($colorGray100, 0) 20%,
      rgba($colorGray100, 0) 80%,
      $colorGray100 100%
    );
  }
}

.stats {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.item {
  position: absolute;
  z-index: 1;
  transform: scale(0.7);
  transform-origin: left top;
}

.value {
  line-height: 3.75rem;
}

.name {
  transform: translateY(-10px);
}

@include media-breakpoint-up(sm) {
  .item {
    transform: scale(1);
  }

  .name {
    transform: none;
  }
}
