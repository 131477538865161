@import '../../sass/variables';

.wrapper {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.imageWrapper {
  position: relative;
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.name {
  display: block;
  font-size: 1rem;
}

// Themes
.square {
  .avatar {
    border-radius: 15px;
  }
}

.round {
  .avatar {
    border-radius: 50%;
  }
}

// Colors
.light {
  color: $colorWhite;

  .company {
    opacity: 0.7;
  }
}

.dark {
  color: $colorGray900;
}

@include media-breakpoint-up(md) {
  .name {
    margin-bottom: 5px;
  }
}
