@import '../../../../sass/variables';

.wrapper {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, $colorWhite 50%, $colorGray100 100%);
  border-bottom: 1px solid $colorGray200;
}

.container {
  position: relative;
  z-index: 1;
}

.titleWrapper {
  max-width: 500px;
}

.globe {
  position: absolute;
  top: 200px;
  right: -650px;
  z-index: -1;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 200px;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    content: '';
    background: linear-gradient(to bottom, rgba($colorWhite, 0) 0%, $colorGray100 50%);
  }
}

@include media-breakpoint-up(md) {
  .globe {
    top: auto;
    right: -430px;
    bottom: -235px;

    &::after {
      top: auto;
      bottom: 235px;
      height: 600px;
      background: linear-gradient(to bottom, rgba($colorWhite, 0) 0%, $colorGray100 80%);
    }
  }
}

@include media-breakpoint-up(lg) {
  .titleWrapper {
    max-width: 600px;
  }

  .globe {
    right: -350px;

    &::after {
      height: 400px;
      background: linear-gradient(to bottom, rgba($colorWhite, 0) 0%, $colorGray100 90%);
    }
  }
}
