@import '../../sass/variables';

.inner {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  fill: none !important;
  stroke-dasharray: 5px 1px;
  stroke-width: 2px;
}

.path {
  opacity: 0;
  fill: none !important;
  stroke-width: 1px;
  animation: dash linear alternate forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.squareWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.square {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7px;
  height: 7px;
  border-radius: 1px;
  opacity: 0.5;
  transform: translate(-50%, -50%) scale(0);
  animation: pop 300ms linear alternate forwards;
}

@keyframes pop {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.3);
  }

  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.05);
  }

  70% {
    transform: translate(-50%, -50%) scale(0.9);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

// Align
.center {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

// Color
.dark {
  .overlay {
    stroke: #fafafb;
  }

  .path {
    stroke: $colorGray900;
  }

  .square {
    background: $colorWhite;
    border: 1px solid $colorGray900;
  }
}

.light {
  .overlay {
    stroke: #002b50;
  }

  .path {
    stroke: $colorWhite;
  }

  .square {
    background: $colorBlue800;
    border: 1px solid $colorWhite;
  }
}
