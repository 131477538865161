@import '../../sass/variables';

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px 20px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.noteWrapper {
  grid-column: span 3;
}

.logoWrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;

  &:nth-child(3n) {
    justify-content: center;
  }

  &:nth-child(3n + 1) {
    justify-content: end;
  }
}

// there is max-width intentionally
@media (max-width: 768px) {
  .logoWrapper {
    & > img {
      width: auto;
      max-width: 85px;
      height: 30px;
    }
  }
}

@include media-breakpoint-up(md) {
  .wrapper {
    grid-template-columns: repeat(6, 1fr);
  }

  .noteWrapper {
    grid-column: span 6;
  }

  .logoWrapper {
    display: block;
    width: auto;
    transform: scale(0.9);

    &:nth-child(n) {
      justify-content: center;
    }
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .noteWrapper {
    grid-row: auto;
  }

  .logoWrapper {
    transform: none;
  }
}
