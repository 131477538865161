@import '../../../../../sass/variables';

.item {
  position: relative;
  padding: 30px;

  &:last-child {
    border: none;
  }
}

.icon {
  position: absolute;
  top: 50px;
  right: 40px;
  transform: translateY(2px);
}

@include media-breakpoint-up(sm) {
  .item {
    padding: 40px 30px;
  }
}

@include media-breakpoint-up(lg) {
  .item {
    padding: 50px 30px;
  }
}

@include media-breakpoint-up(lg) {
  .item {
    padding: 50px 35px;
  }
}
