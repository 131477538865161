@import '../../sass/variables';

.paragraph {
  text-align: left;
}

@include media-breakpoint-up(sm) {
  .paragraph {
    text-align: center;
  }
}
