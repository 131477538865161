@import '../../../../sass/variables';

.wrapper {
  overflow: hidden;
}

.tilesWrapper {
  position: relative;
}

.path {
  position: absolute;
  left: 50%;
  z-index: 0;
  transform: translateY(-60px);
}

.tiles {
  max-width: 380px;
  margin: 0 auto;
}

.item {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.itemContent {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  text-align: center;
}

.title {
  text-align: left;
}

.value {
  text-align: left;
}

.mapTitle {
  position: relative;
  z-index: 1;
}

@include media-breakpoint-up(sm) {
  .mapTitle {
    transform: translateY(24px);
  }
}

@include media-breakpoint-up(md) {
  .tiles {
    max-width: 975px;
  }

  .item {
    flex-direction: column;
    gap: 20px;
    padding: 40px 25px;
  }

  .itemContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
  }

  .title {
    text-align: center;
  }

  .value {
    text-align: center;
  }

  .mapTitle {
    transform: translateY(calc(100% + 24px));
  }
}

@include media-breakpoint-up(lg) {
  .item {
    padding: 50px 40px;
  }
}
