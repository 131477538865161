@import '../../sass/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background: rgba($colorWhite, 60%);
  border: 1px solid rgba($colorBlue250, 20%);
  border-radius: 12px;
  backdrop-filter: blur(10px);

  &.higherContrast {
    backdrop-filter: contrast(1.2) blur(10px);
  }
}

.item {
  border-bottom: 1px solid rgba($colorBlue250, 20%);

  &:last-child {
    border: none;
  }
}

@include media-breakpoint-up(md) {
  .wrapper.layout-2-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    background: none;
    border: none;
    backdrop-filter: none;

    &.higherContrast {
      backdrop-filter: none;
    }

    .item {
      &:nth-child(n) {
        background: rgba($colorWhite, 60%);
        border: 1px solid rgba($colorBlue250, 20%);
        backdrop-filter: blur(10px);
      }

      &:nth-child(2) {
        grid-row: span 2;
        margin-left: -1px;
        border-radius: 0 12px 12px 0;
      }

      &:first-child {
        border-radius: 12px 12px 0 0;
      }

      &:last-child {
        margin-top: -1px;
        border-radius: 0 0 12px 12px;
      }
    }
  }

  .wrapper.layout-3-columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .item {
      border: none;
      border-right: 1px solid rgba($colorBlue250, 20%);

      &:last-child {
        border: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .wrapper.layout-2-columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background: rgba($colorWhite, 60%);
    border: 1px solid rgba($colorBlue250, 20%);
    backdrop-filter: blur(10px);

    &.higherContrast {
      backdrop-filter: contrast(1.2) blur(10px);
    }
  }

  .item {
    height: 100%;
    margin: 0 !important;
    background: none !important;
    border: none !important;
    border-right: 1px solid rgba($colorBlue250, 20%) !important;
    border-radius: 0 !important;
    backdrop-filter: none !important;

    &:last-child {
      border-right: none !important;
    }
  }
}
