@import '../../sass/variables';

.paragraph {
  display: block;
}

.isCentered {
  margin-right: auto;
  margin-left: auto;
}

// Align
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

// Color
.light {
  &.opacity-60 {
    color: rgba($colorWhite, 0.6);
  }

  &.opacity-80 {
    color: rgba($colorWhite, 0.8);
  }

  &.opacity-100 {
    color: $colorWhite;
  }
}

.default {
  &.opacity-60 {
    color: rgba($colorGray500, 0.6);
  }

  &.opacity-80 {
    color: rgba($colorGray500, 0.8);
  }

  &.opacity-100 {
    color: $colorGray500;
  }
}

.dark {
  &.opacity-60 {
    color: rgba($colorGray900, 0.6);
  }

  &.opacity-80 {
    color: rgba($colorGray900, 0.8);
  }

  &.opacity-100 {
    color: $colorGray900;
  }
}

// Size
.inherit {
  font-size: inherit;
}

.xs {
  font-size: 0.8125rem;
}

.sm {
  font-size: 0.875rem;
}

.md {
  font-size: 0.9375rem;
  line-height: 30px;
}

.lg {
  font-size: 1rem;
  line-height: 34px;
}

.xl {
  font-size: 1.125rem;
  line-height: 36px;
}

@include media-breakpoint-up(md) {
  .xs {
    font-size: 0.875rem;
  }

  .sm {
    font-size: 0.9375rem;
  }

  .md {
    font-size: 1rem;
  }

  .lg {
    font-size: 1.125rem;
  }

  .xl {
    font-size: 1.25rem;
  }
}
