@import '../../../../../sass/variables';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 475px;
  transition: opacity 0.1s linear;
}

.content {
  position: relative;
}

.refWrapper {
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 1px;
  height: 80vh;
  transform: translateY(-50%);
}

@media (max-width: 992px) {
  .wrapper {
    opacity: 1 !important;
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    padding: 60px 0 0;

    &:last-child {
      min-height: 600px;
    }
  }

  .image {
    display: none;
  }
}
